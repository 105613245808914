import React from "react";
import Footer from "../components/Footer";
import { useSpecifiedContent } from "../hooks/contentHooks";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';


export const About = () => {
  const [content, isLoading] = useSpecifiedContent('about');

  return (
    <>
    <div className="text">
      <h1 className="pageTitle">About</h1>
      {!isLoading && content ? documentToReactComponents(content) :
        (<>
          <p>
            Tim is an experienced and highly esteemed sprint coach and strength &
            conditioning coach. With a master’s degree in Strength & Conditioning,
            Tim possesses an impressive depth of knowledge and skill set.
          </p>
          <p>
            Currently Tim is the head sprint coach for Hunter Sports High School and
            runs the Newcastle Speed Academy. Tim’s career highlights have seen him
            working as the head strength & conditioning coach at Moreton Bay Girls'
            College and the head track coach at Moreton Bay Boys' College. Throughout
            his career, Tim has worked with many clients ranging from world class
            rugby union players and national level soccer players, to talented junior
            athletes and veteran athletes, with proven results and improvements.
            Specifically, for sprinting, Tim has worked with athletes training for
            amateur racing, the pro-running circuit and beach sprinting.
          </p>
          <p>
            Tim’s calm, no-nonsense and honest demeanour when it comes to training allows him to develop a strong rapport with a range of clients. His holistic approach to wellbeing also ensures the long-term health and performance of his athletes. Tim’s passion lies in getting the best out of each of his athletes and developing a tailored program and watching the growth and results unfold!
          </p>
          <p>
            Tim is a talented and passionate athlete himself, and has been heavily involved in sport, specifically athletics, for his entire life, culminating in making the finals of two Stawell Gifts.
          </p>
        </>)
      }
    </div>
    <Footer />
    </>
  );
};
