import { createClient } from 'contentful';

const client = createClient({
  space: process.env.REACT_APP_CONTENFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
})

const getContent = () => client.getEntries().then(response => response.items)


const getSpecificField = slug =>
  client
    .getEntry(slug)
    .then(response => response)

export { getContent, getSpecificField}