import React from "react";
import Footer from "../components/Footer";

export const Contact = () => {
  return (
    <>
      <div className="text">
        <h1 className="pageTitle">Contact</h1>
        <div className="contactForm">
          <form name="contact" method="POST">
            <h2>Get in touch</h2>
            <input type="hidden" name="form-name" value="contact" />
            <input
              placeholder="Your name"
              type="text"
              name="name"
              autoComplete="name"
            />
            <input
              placeholder="Your email"
              type="email"
              name="email"
              autoComplete="email"
            />
            <textarea
              placeholder="Your message"
              name="message"
              id="message"
            ></textarea>
            <input type="submit" value="Send" />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
