import { useEffect, useState } from 'react'

import { getContent, getSpecificField } from '../contentful'


export function useSpecifiedContent(fieldName) {
  const [content, setContent] = useState([])
  const [isLoading, setLoading] = useState(true)
  const promise = getSpecificField(process.env.REACT_APP_ID)

  useEffect(() => {
    promise.then(userContent => {
      const content = userContent.fields[fieldName]
      setContent(content)
      setLoading(false)
    })
  }, [])

  return [content, isLoading]
}

export default function useContent() {
  const [content, setContent] = useState([])
  const [isLoading, setLoading] = useState(true)
  const promise = getContent()

  useEffect(() => {
    promise.then(userContent => {
      setContent(userContent)
      setLoading(false)
    })
  }, [])

  return [content, isLoading]
}