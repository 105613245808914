import React from 'react';
import { NavLink } from "react-router-dom";

export const Links = ({ inModal, closeModal }) => {
  return (
    <ul className="links">
      {inModal && (
        <li>
          <NavLink onClick={closeModal}   className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "selected" : ""
  }to="/" exact>
            HOME
          </NavLink>
        </li>
      )}
      <li>
        <NavLink onClick={closeModal}   className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "selected" : ""
  } to="/about">
          ABOUT
        </NavLink>
      </li>
      <li>
        <NavLink onClick={closeModal}   className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "selected" : ""
  } to="/services">
          COACHING SERVICES
        </NavLink>
      </li>
      <li>
        <NavLink onClick={closeModal}   className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "selected" : ""
  } to="/pricing">
          PRICING & CALENDAR
        </NavLink>
      </li>
      <li>
        <NavLink onClick={closeModal}   className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "selected" : ""
  } to="/gallery">
          GALLERY
        </NavLink>
      </li>
      <li>
        <NavLink onClick={closeModal}   className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? "selected" : ""
  } to="/contact">
          CONTACT
        </NavLink>
      </li>
    </ul>
  );
}