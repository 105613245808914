import React from "react";
import { CovidBanner } from "../components/covid";
import { useSpecifiedContent } from "../hooks/contentHooks";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Footer from "../components/Footer";

export const Pricing = () => {
    const [content, isLoading] = useSpecifiedContent('pricing');
  return (
    <>
      <CovidBanner />
      <div className="text">
        <h1 className="pageTitle">Pricing & Calendar</h1>
       {!isLoading && content ? 
       (<div className="pricing">{documentToReactComponents(content)}</div>) : 
       (<>
        <div className="pricing">
          <h2>Pricing</h2>
          <p>
            Casual squad session (45 min sprint or S&C session): $30/session
          </p>
          <p>
            Elite package (3 x squad sprint session and 2 x S&C squad session):
            $100/week or $400/month
          </p>
          <p>Private session (sprint or S&C): $70/30mins</p>
          <small>*All prices include facility entry</small>
        </div>
        <br />
        <div className="calendar">
          <h2>When and Where</h2>
          <div>
            <h4>Sprint squad training </h4>
            <p>
              <strong>Location: </strong>
              Fearnley Dawes Athletic Centre, Newcastle West NSW
            </p>
            <p>
              <strong>Weekly sessions: </strong>
              Monday 4pm, Wednesday 5pm, Saturday 7am
            </p>
          </div>
          <div>
            <h4>Strength and conditioning training</h4>
            <p>
              <strong>Location: </strong>Fearnley Dawes Athletic Centre,
              Newcastle West NSW
            </p>
            <p>
              <strong>Weekly sessions: </strong>
              Monday 5pm, Wednesday 6pm, Saturday 8am
            </p>
          </div>
          <div>
            <h4>Sprint Clinics</h4>
            <p>
              <strong>Location: </strong>Fearnley Dawes Athletic Centre,
              Newcastle West NSW
            </p>
            <p>
              Sessions are run during school holidays. Please contact us for
              details.
            </p>
          </div>
        </div>
      </>)}
      </div>
      <Footer />
    </>
  );
};
