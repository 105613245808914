export const photos = [
         {
           src:
             "/photos/photo1.jpg",
           width: 5,
           height: 3
         },
         {
           src:
             "/photos/photo2.jpg",
           width: 4,
           height: 3
         },
         {
           src:
             "https://live.staticflickr.com/65535/49737674767_2053623620_z.jpg",
           width: 640,
           height: 360
         },
         {
           src:
             "https://live.staticflickr.com/65535/49737344481_e6e4e0b8e7_b.jpg",
           width: 5,
           height: 3
         },
         {
           src:
             "https://live.staticflickr.com/65535/49737676387_f8a6a45ef6_z.jpg",
           width: 1,
           height: 1
         },
         {
           src:
             "https://live.staticflickr.com/65535/49736801393_af2afb3ff0_z.jpg",
           width: 640,
           height: 427
         },
         {
           src:
             "https://live.staticflickr.com/65535/49736804628_12c763a2c2_z.jpg",
           width: 5,
           height: 3
         },
         {
           src:
             "https://live.staticflickr.com/65535/49737676137_9339bbe4fa_z.jpg",
           width: 5,
           height: 3
         },
         {
           src:
             "https://live.staticflickr.com/65535/49736804618_1a8fdc3bdf_z.jpg",
           width: 5,
           height: 3
         },
         {
           src:
             "https://live.staticflickr.com/65535/49736804468_35ce469237_z.jpg",
           width: 1,
           height: 1
         },
         {
           src:
             "https://live.staticflickr.com/65535/49737344266_83717530e2_z.jpg",
           width: 4,
           height: 3
         },
         {
           src:
             "https://live.staticflickr.com/65535/49737344216_4373d5a9c2_z.jpg",
           width: 4,
           height: 3
         },
         {
           src:
             "https://live.staticflickr.com/65535/49737676162_dc3f258808_z.jpg",
           width: 5,
           height: 3
         },
         {
           src:
             "https://live.staticflickr.com/65535/49737343511_855d98778f_z.jpg",
           width: 640,
           height: 360
         },
         {
           src: "https://live.staticflickr.com/65535/49737673657_80fc27bcc1_z.jpg",
           width: 640,
           height: 360
         },
         {
           src: "https://live.staticflickr.com/65535/49737672757_86122f130e_z.jpg",
           width: 640,
           height: 427
         },
         {
           src: "https://live.staticflickr.com/65535/49737669807_38595e02d4_z.jpg",
           width: 640,
           height: 427
         },
         {
           src: "https://live.staticflickr.com/65535/49737668947_24659eea38_z.jpg",
           width: 640,
           height: 360
         }
       ];
