import React from "react";
import PhotoAlbum from "react-photo-album";
import Footer from "../components/Footer";
import { photos } from '../assets/photoPath';
import { useSpecifiedContent } from "../hooks/contentHooks";

export const PhotoGallery = () => {
  const [content, isLoading] = useSpecifiedContent('gallery');
  const images = !isLoading && content && content.map((c) => { 
    if (c && c.fields && c.sys && c.sys.type === 'Asset') {
      return {
        src: c.fields.file.url,
        width: c.fields.file.details.image.width,
        height: c.fields.file.details.image.height
      }
    }
  }).filter(item => item !== undefined);

  return (
    <div>
      <div className="gallery">
        <div className="galleryContent">Gallery content</div>
        {!isLoading && content && images ?
            <PhotoAlbum layout="rows" photos={images} />
          : <PhotoAlbum layout="rows" photos={photos} />
        }
      </div>
      <Footer />
    </div>
  );
};
