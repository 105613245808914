import React from 'react';

export const CovidBanner = () => {
  return (
    <div className="covid">

      <p>
        <strong>COVID-19 Update: </strong>Regular coaching services have resumed
        with social distancing in place.
      </p>
    </div>
  );
}