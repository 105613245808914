import React from "react";
import FontAwesome from "react-fontawesome";
import { Links } from "./Links";
import logo from "../logo.png";

export const Menu = (props) => {
  const { closeModal } = props;

  const closeicon = () => (
    <FontAwesome
      name="times"
      className="close"
      onClick={closeModal}
    />
  );

  return (
    <div className="overlay menu">
      {closeicon()}
      <img src={logo} className="logo" alt="logo" height={100} />
      <Links inModal={true} closeModal={closeModal}/>
    </div>
  );
};
