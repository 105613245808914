import React from "react";
import { CovidBanner } from "../components/covid";
import Footer from "../components/Footer";
import { useSpecifiedContent } from "../hooks/contentHooks";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const CoachingServices = () => {
    const [content, isLoading] = useSpecifiedContent('coachingServices');

  return (
    <>
      <CovidBanner />
      <div className="text">
        <h1 className="pageTitle">Coaching Services</h1>
        {!isLoading && content ? documentToReactComponents(content) :
        (<>
        <h2>Sprint Training</h2>
        <h3>Track Sprinting</h3>
        <p>
          Track sprint training improves speed, endurance and fitness in
          athletes which are key attributes in many sports, not just for track
          sprinters. Training sessions focus on technique improvement through
          all phases of sprinting. Sessions are developed based on each
          athlete’s individual goals and abilities.
        </p>
        <p>
          The primary location for track sprinting sessions is at Fearnley Dawes
          Athletic Centre, Newcastle West, NSW.
        </p>
        <p>
          It is highly recommended that track sprinting sessions are
          complemented by strength and conditioning sessions.
        </p>
        <h3>Beach Sprinting</h3>
        <p>
          Training on the beach is not just for beach sprinters but is
          beneficial for all sprinters as well as all athletes that require
          endurance, strength and speed. Types of beach sprint training includes
          sand dunes and soft sand sprinting. It’s also a great way to spice
          things up in your training to keep things interesting!
        </p>
        <p>
          The location for beach sprint training varies among the many beaches
          across Newcastle and Port Stephens. It will typically be followed by a
          well-earned, refreshing swim at the beach!
        </p>

        <br />
        <h2>Strength and Conditioning Coaching</h2>
        <p>
          Strength and conditioning training is an essential and complementary
          aspect to improve your performance in sport through speed, strength
          and power. The key focus of the strength and conditioning training
          sessions is on proper lifting techniques and to reduce athletic
          injuries. Tailored training programs are developed for teams and
          individual athletes.
        </p>
        <p>
          The main location for strength and conditioning coaching sessions is
          at Fearnley Dawes Athletic Centre in Newcastle.
        </p>

        <br />
        <h2>School/Group Training</h2>
        <p>
          Group training for sports teams or schools is available across the
          Newcastle, Maitland and Port Stephens area. Please get in touch to
          discuss how we can help you improve your team’s performance!
        </p>

        <br />
        <h2>One on One Coaching</h2>
        <p>
          One on one, or private, coaching is available to those athletes who
          require extra training to develop their techniques and performance.
          One on one coaching is available for both sprint and strength and
          conditioning based training.
        </p>
      </>)}
      </div>
      <Footer />
    </>
  );
};
