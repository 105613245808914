import React from "react";

const Footer = () => {
  return (
    <footer>
      <p>
        <strong>Email: </strong>teschebach@gmail.com
      </p>
      <p>
        <strong>Phone: </strong>0490191665
      </p>
      <p className="insta">
        <strong>Instagram: </strong>
        <a href="https://www.instagram.com/tim_esch_s_and_c/">
          @tim_esch_s_and_c
        </a>
        <a href="https://www.instagram.com/nsa_newcastle_speed_academy/">
          @nsa_newy_speed_academy
        </a>
      </p>
    </footer>
  );
};

export default Footer;
