import React from "react";
import logo from "../logo.png";

export const Home = () => {
  return (
    <span className="home">
      <img src={logo} className="homeLogo" alt="logo" />
      <h1 className="pageName">Tim Eschebach Strength & Conditioning</h1>
      <span className="details">
        <p>
          <a href="mailto: teschebach@gmail.com">teschebach@gmail.com</a>
        </p>
        <p>|</p>
        <p>
          <a href="https://www.instagram.com/tim_esch_s_and_c/">
            @tim_esch_s_and_c
          </a>
        </p>
      </span>
    </span>
  );
};
