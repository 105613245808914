import React from "react";
import logo from "../logo.png";

export const NavLogo = (props) => {
  const { pageName } = props;
  return (
    <span className="navLogo">
      <aside className="logolink">
        <a href="/">
          <img src={logo} className="logo" alt="logo" height={100} />
        </a>
      </aside>
      <section className="title">
        <a href="/">
          <p className="name">Tim Eschebach</p>
          <p className="tag">Strength & Conditioning</p>
        </a>
        <p className="page">{pageName}</p>
      </section>
    </span>
  );
};
