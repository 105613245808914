import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";

import { Header } from "./components/Header";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { CoachingServices } from "./pages/CoachingServices";
import { Pricing } from "./pages/Pricing";
import { PhotoGallery } from "./pages/Gallery";
import { Contact } from "./pages/Contact";
import "./styles/App.scss";

import { Menu } from "./components/Menu";
import { ScrollToTop } from "./components/ScrollToTop";
import { ThankYou } from "./pages/ThankYou";


const App = () => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="App">
        <button className="menu-btn" onClick={() => setStatus(true)}>
          MENU
        </button>
        {status && <Menu closeModal={() => setStatus(false)} />}
        <Header />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/services" element={<CoachingServices/>} />
          <Route path="/pricing" element={<Pricing/>} />
          <Route path="/gallery" element={<PhotoGallery/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/thankyou" element={<ThankYou/>} />
          <Route render={() => <h1>Coming soon!</h1>} />
        </Routes>
      </div>
    </>
  );
};

export default App;
