import React from "react";
import { useLocation } from "react-router-dom";
import { NavLogo } from "./NavLogo";
import { Links } from "./Links";

const getPageName = (path) => {
  switch (path) {
    case "services":
      return "Coaching Services";
    case "about":
      return "About";
    case "gallery":
      return "Gallery";
    case "pricing":
      return "Pricing & Calendar";
    case "contact":
      return "Contact";
    default:
      return "";
  }
};

const Header = () => {
  const location = useLocation();
  const notAtHome = location.pathname !== "/";
  const path = location.pathname.split("/")[1];

  const pageName = getPageName(path);

  return (
    <header>
      {notAtHome && <NavLogo pageName={pageName} />}
      <Links />
    </header>
  );
};

export { Header };
